// extracted by mini-css-extract-plugin
export var ArtistDescription = "Daze-module--ArtistDescription--dTJU5";
export var ArtistInfos = "Daze-module--ArtistInfos--BBxdL";
export var ButtonWrapper = "Daze-module--ButtonWrapper --YmQmT";
export var CardWrapper = "Daze-module--CardWrapper--Oxo3d";
export var CarrouselWrapper2 = "Daze-module--CarrouselWrapper2--3sFn2";
export var Citations = "Daze-module--Citations--Fo9ot";
export var DescriptionWrapper = "Daze-module--DescriptionWrapper--JVPcc";
export var ImageWrapper = "Daze-module--ImageWrapper--WyUIJ";
export var LinkWrapper = "Daze-module--LinkWrapper--erndC";
export var MobileProtrait = "Daze-module--MobileProtrait--vV1Gt";
export var More = "Daze-module--More--K-lww";
export var MoreButton = "Daze-module--MoreButton--VHnlD";
export var NameWrapper = "Daze-module--NameWrapper--Ko4fE";
export var PdpWrapper = "Daze-module--PdpWrapper--5QUZA";
export var PhotosWrapper = "Daze-module--PhotosWrapper--zohok";
export var ProfilWrapper = "Daze-module--ProfilWrapper--vGM8q";
export var TitleWrapper = "Daze-module--TitleWrapper--ehbFz";
export var Wrapper = "Daze-module--Wrapper--JH8Xl";