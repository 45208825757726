import * as React from "react";
import Layout from "./../../../components/en/Layout";
import Daze from "./../../../nav/en/Artistes/Daze";
import { Helmet } from "react-helmet";

const DazePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DAZE | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,graffitilegend,graffitimaster,graffitiphotos,graffiticulture,graffitinyc,netflixhiphop,dazegraffiti,chrisellis,chrisdazeellis,chrisellispainting,chrisellisdrawing,chrisellisgallery,chrisellisgalerie,chrisellispeinture,chrisellisdessin,chrisellishistory,chrisellishistoire,chrisellisgraffiti,chriselliscontemporaryart,chrisellisartcontemporain,chriselliscollection,chrisellisnyc,chrisellisbiography,chrisellisbiographie,chrisellisdaze,dazepainting,dazegallery,dazegalerie,dazeforsale,dazebook,dazesubway,dazespraypaint,dazeaerosol,daze,dazestudioart,dazephotograffiti,dazeportrait,dazearchives,dazeretrospectiveexhibition,dazegraffitinyc,dazegraffitihistory,dazegraffitiart,graffitiart,dazenyc,artcollectiongraffiti,artcollectioncontemporaryart"/>
      </Helmet>
      <Daze />
    </Layout>
  );
};

export default DazePage;
